import React, { useContext } from 'react'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import isUndefined from 'lodash.isundefined'
import { createParam } from 'solito'
import { trpc } from '@centrito/app/api/trpc'
import CategoryTree from '@centrito/app/components/Feed/FilterBar/CategoryTree'
import { FilterOverlayContext } from '@centrito/app/components/Feed/FilterBar/Overlay/context'
import { type FilterOverlay } from '@centrito/app/components/Feed/FilterBar/Overlay/context/types'
import FeedFilterBarRibbon from '@centrito/app/components/Feed/FilterBar/Ribbon'
import { YStack } from '@centrito/ui/src'

type Params = {
  searchTerm?: string
  merchantId?: string
  brandId?: string
}

const { useParams } = createParam<Params>()

export const WrappedRibbon: React.FC<{ productsCount?: number }> = ({ productsCount }) => {
  const { params } = useParams()

  const { isVisibleOverlay, setIsVisibleOverlay, setCurrentFilterDrawer } =
    useContext(FilterOverlayContext)

  const toggleOverlay = (key?: FilterOverlay.OverlayFilterType): void => {
    setIsVisibleOverlay(!isVisibleOverlay)
    if (!isUndefined(key)) {
      setCurrentFilterDrawer(key)
    } else {
      setCurrentFilterDrawer(undefined)
    }
  }
  const { data: orderCount } = trpc.catalog.merchant.getOrderCountById.useQuery({
    merchantId: params.merchantId ?? '',
  })

  const { data: merchantData } = trpc.catalog.merchant.findUnique.useQuery(
    {
      merchantId: params.merchantId ?? '',
    },
    {
      enabled: !isNil(params.merchantId),
    },
  )

  const { data: brandData } = trpc.catalog.brand.findUnique.useQuery(
    {
      brandId: params.brandId ?? '',
    },
    {
      enabled: !isNil(params.brandId) && !isEmpty(params.brandId),
    },
  )

  const sanitizeSearchTerm = (): string | undefined => {
    return params?.searchTerm ? decodeURIComponent(params.searchTerm) : undefined
  }

  return (
    <YStack>
      <CategoryTree
        isLoading={isNil(productsCount)}
        searchTerm={sanitizeSearchTerm()}
        count={productsCount}
        merchantInfo={{ orderCount, name: merchantData?.name ?? '' }}
        brandInfo={brandData?.brand}
      />
      <FeedFilterBarRibbon onFilterPress={toggleOverlay} />
    </YStack>
  )
}
