import React from 'react'
import { X } from '@tamagui/lucide-icons'
import isNil from 'lodash.isnil'
import { createParam } from 'solito'
import { GetProductsFilterType as FilterType } from '@centrito/api/shared/enums'
import { getColorHexFromName } from '@centrito/app/utils/components/FeedView/ColorBubble'
import { Circle, XStack } from '@centrito/ui/src'
import { DefaultTextPrimary } from '@centrito/ui/src/components/text'

interface FeedFilterBarPillItemProps {
  name: string
  filterType: FilterType
  onClickRemove(): Promise<void>
}

const { useParams } = createParam()

const FeedFilterBarPillItem: React.FC<FeedFilterBarPillItemProps> = ({
  name,
  filterType,
  onClickRemove,
}) => {
  const { params } = useParams()

  if (filterType == FilterType.SUPPLIER && !isNil(params?.merchant)) {
    return <React.Fragment />
  }

  return (
    <XStack
      paddingHorizontal={5}
      paddingVertical={8}
      borderRadius={8}
      backgroundColor="#8C00281A"
      alignItems="center"
      justifyContent="center"
      gap={4}
      onPress={onClickRemove}
    >
      {filterType === FilterType.COLOR && (
        <Circle size={16} backgroundColor={getColorHexFromName(name)} marginRight={6} />
      )}
      <DefaultTextPrimary fontSize={14}>{name}</DefaultTextPrimary>
      <X color="#7f7f7f" size={15} />
    </XStack>
  )
}

export default FeedFilterBarPillItem
