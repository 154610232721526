import React, { useState } from 'react'
import PATHS from '@centrito/common/paths'
import type { CategoryPublic } from '@centrito/api/nest/platform/database/domain'
import SeeMoreOrLessButton from '@centrito/app/components/Feed/components/SeeMoreOrLessButton'
import useWindowDimensions from '@centrito/app/utils/hooks/useWindowDimensions'
import { XStack, YStack } from '@centrito/ui/src'
import { CircleFeedButton } from '@centrito/ui/src/components/button/CircleFeedButton'

interface ProductListSeeMoreCategoriesProps {
  categories: CategoryPublic[]
  listId: string
}

export const ProductListSeeMoreCategories: React.FC<ProductListSeeMoreCategoriesProps> = ({
  categories,
  listId,
}) => {
  const [isShowingAll, setIsShowingAll] = useState<boolean>(false)
  const { width: windowWidth } = useWindowDimensions()
  const displayItems = Math.round(windowWidth / 85)
  const displayedCategoriesCount = displayItems * 2 - 1

  return (
    <YStack alignItems="center" alignContent="center" justifyContent="center">
      <XStack
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="flex-start"
        maxWidth={displayItems * 80}
      >
        {categories
          .slice(0, isShowingAll ? categories.length : displayedCategoriesCount)
          .map((category) => (
            <CircleFeedButton
              key={`category-browser--${category.nodes}`}
              href={{
                pathname: PATHS.Products.List,
                query: {
                  selectedCategory: category.nodes,
                  listId: listId,
                },
              }}
              imageSrc={category.assets.tab || ''}
              imageAlt={category.nodes}
              label={category.nodesNamed.split('-').pop() || ''}
            />
          ))}
        {categories.length > displayedCategoriesCount && (
          <YStack onPress={(): void => setIsShowingAll(!isShowingAll)} minWidth={80}>
            <SeeMoreOrLessButton isShowingAll={isShowingAll} width={80} />
          </YStack>
        )}
      </XStack>
    </YStack>
  )
}
