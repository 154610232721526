import type { Dispatch, SetStateAction } from 'react'
import isNil from 'lodash.isnil'
import { GetProductsFilterType } from '@centrito/api/shared/enums'
import { changeItemsOnCheck } from '@centrito/app/components/Feed/FilterBar/Overlay/utils'
import { FILTER_OVERLAY_OPTION_GROUP } from './constants'
import type { FilterOverlay } from './types'

const useChangeOptionItemIsChecked = (
  currentOptions: FilterOverlay.Options,
  setCurrentOptions: Dispatch<SetStateAction<FilterOverlay.Options>>,
): { changeOptionItemIsChecked: FilterOverlay.ChangeOptionItemIsCheckedFn } => {
  const changeOptionItemIsChecked: FilterOverlay.ChangeOptionItemIsCheckedFn = (
    targetOptionItem,
  ) => {
    const targetFilterType = targetOptionItem._filterItem.type

    if (
      targetFilterType === GetProductsFilterType.BRAND_ID ||
      targetFilterType === GetProductsFilterType.CATEGORY ||
      targetFilterType === GetProductsFilterType.COLOR ||
      targetFilterType === GetProductsFilterType.SIZE ||
      targetFilterType === GetProductsFilterType.SUPPLIER
    ) {
      const optionItems = currentOptions[targetFilterType]

      if (!isNil(optionItems)) {
        const isExclusive = FILTER_OVERLAY_OPTION_GROUP?.[targetFilterType]?.isExclusive ?? false
        const newOptionItems = changeItemsOnCheck(optionItems, isExclusive, targetOptionItem)
        setCurrentOptions({
          ...currentOptions,
          [targetFilterType]: newOptionItems,
        })
      }
    }
  }

  return { changeOptionItemIsChecked }
}

export default useChangeOptionItemIsChecked
