import React, { useContext } from 'react'
import { FilterOverlayContext } from '@centrito/app/components/Feed/FilterBar/Overlay/context'
import type { FilterOverlay } from '@centrito/app/components/Feed/FilterBar/Overlay/context/types'
import { XStack, YStack } from '@centrito/ui/src'
import { DefaultTextTertiary } from '@centrito/ui/src/components/text'

interface ColorOptionItemProps {
  optionItem: FilterOverlay.OptionItem
}

export const ColorOptionItem: React.FC<ColorOptionItemProps> = ({ optionItem }) => {
  const { changeOptionItemIsChecked } = useContext(FilterOverlayContext)
  const { icon, name, isChecked } = optionItem

  return (
    <YStack
      onPress={(): void => changeOptionItemIsChecked(optionItem)}
      alignItems="center"
      width="25%"
      rowGap={4}
    >
      <XStack
        {...(isChecked ? { borderColor: '#55808A', borderWidth: 2, padding: 2 } : { padding: 4 })}
        borderRadius={9}
      >
        {icon}
      </XStack>
      <DefaultTextTertiary
        color={isChecked ? '#55808A' : '#7F7F7F'}
        textAlign="center"
        alignSelf="center"
        numberOfLines={2}
        maxWidth="80%"
      >
        {name}
      </DefaultTextTertiary>
    </YStack>
  )
}
