import type { Dispatch, SetStateAction } from 'react'
import { trpc } from '@centrito/app/api/trpc'
import getInitialOptions from './getInitialOptions'
import type { FilterOverlay } from './types'

const useResetOptions = (
  setOptions: Dispatch<SetStateAction<FilterOverlay.Options>>,
): { resetOptions: FilterOverlay.ResetOptionsFn } => {
  const { data: relevantMerchants } = trpc.catalog.filters.getMerchants.useQuery({ filters: {} })
  const { data: relevantBrands } = trpc.catalog.filters.getBrands.useQuery({ filters: {} })
  const { data: relevantCategories } = trpc.catalog.filters.getCategories.useQuery({
    filters: {},
  })
  const { data: relevantColors } = trpc.catalog.filters.getColors.useQuery({ filters: {} })
  const { data: relevantSizes } = trpc.catalog.filters.getSizes.useQuery({ filters: {} })
  const { data: priceRange } = trpc.catalog.filters.getPricing.useQuery({ filters: {} })

  const resetOptions: FilterOverlay.ResetOptionsFn = (filters) => {
    const newOptions = getInitialOptions(
      filters,
      relevantCategories ?? [],
      relevantBrands ?? [],
      relevantMerchants ?? [],
      relevantColors ?? [],
      relevantSizes ?? [],
      priceRange?.pricing ?? { low: 5000, high: 150000 },
    )
    setOptions(newOptions)
  }

  return { resetOptions }
}

export default useResetOptions
