import React from 'react'
import { Text, type TextProps } from 'tamagui'

export const DetailText1: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontFamily="$poppinsFont" fontSize={10} fontWeight="600" {...rest}>
      {children}
    </Text>
  )
}
