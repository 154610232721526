import { type Dispatch, type SetStateAction } from 'react'
import isNil from 'lodash.isnil'
import { GetProductsFilterType } from '@centrito/api/shared/enums'
import type { FilterOverlay } from './types'

const useChangeOptionsPriceRange = (
  currentOptions: FilterOverlay.Options,
  setCurrentOptions: Dispatch<SetStateAction<FilterOverlay.Options>>,
): { changePriceFilters: (low: number, high: number) => void } => {
  const changePriceFilters: (low: number, high: number) => void = (low: number, high: number) => {
    const updatedOptions: FilterOverlay.Options = {
      ...currentOptions,
      [GetProductsFilterType.PRICE_RANGE]: [
        ...(!isNil(currentOptions[GetProductsFilterType.PRICE_RANGE])
          ? [
              {
                ...currentOptions[GetProductsFilterType.PRICE_RANGE][0],
                _filterItem: { type: GetProductsFilterType.PRICE_RANGE, value: { low, high } },
              },
            ]
          : []),
      ],
    }

    if (!isNil(updatedOptions)) {
      setCurrentOptions(updatedOptions as FilterOverlay.Options)
    }
  }
  return { changePriceFilters }
}

export default useChangeOptionsPriceRange
