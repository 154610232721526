import React from 'react'
import isNil from 'lodash.isnil'
import { createParam } from 'solito'
import { trpc } from '@centrito/app/api/trpc'
import { ProductListCategoriesSlider } from '@centrito/app/components/Feed/FilterBar/ProductList/ProductListCategoriesSlider'
import { ProductListSeeMoreCategories } from '@centrito/app/components/Feed/FilterBar/ProductList/ProductListSeeMoreCategories'

type ProductListParams = {
  listId: string
  selectedCategory?: string
}

const { useParams } = createParam<ProductListParams>()

const ProductListCategoriesBar: React.FC = () => {
  const {
    params: { selectedCategory, listId },
  } = useParams()

  const uniqueCategories = trpc.catalog.category.findByProductList.useQuery(
    { listId, selectedCategory: selectedCategory ?? '' },
    { enabled: !isNil(listId) },
  ).data

  if (isNil(uniqueCategories) || uniqueCategories?.length === 0) {
    return <React.Fragment />
  }

  if (uniqueCategories?.length < 10) {
    return <ProductListCategoriesSlider categories={uniqueCategories} listId={listId} />
  }

  return <ProductListSeeMoreCategories categories={uniqueCategories} listId={listId} />
}

export default ProductListCategoriesBar
