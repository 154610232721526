import React from 'react'
import { type TextProps } from 'tamagui'
import { DefaultTextTertiary } from './DefaultTextTertiary'

export const BoldTextTertiary: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <DefaultTextTertiary fontWeight="700" {...rest}>
      {children}
    </DefaultTextTertiary>
  )
}
