import React, { useContext } from 'react'
import isNil from 'lodash.isnil'
import { createParam } from 'solito'
import { FilterOverlayContext } from '@centrito/app/components/Feed/FilterBar/Overlay/context'
import useActiveFeedFilterPills from '@centrito/app/utils/hooks/useActiveFeedFilterPills'
import { useFiltersQuery } from '@centrito/app/utils/hooks/useFeedProducts/useFiltersQuery'
import { XStack } from '@centrito/ui/src'
import Pill from './Item'

type FilterParamsCatalog = {
  brandId?: string
  merchantId?: string
}

const { useParams } = createParam<FilterParamsCatalog>()

const FeedFilterBarPillGroup: React.FC = () => {
  const { activeFeedFilterPills } = useActiveFeedFilterPills()
  const { removeFilter } = useFiltersQuery()
  const { resetOptions } = useContext(FilterOverlayContext)
  const { params } = useParams()

  const excludedIds = [params.brandId, params.merchantId].filter((id) => !isNil(id))

  return (
    activeFeedFilterPills.length > 0 && (
      <XStack
        flexWrap="wrap"
        paddingHorizontal={14}
        paddingVertical={8}
        gap={8}
        width="100%"
        borderTopColor="#e9e9ea"
        borderTopWidth={1}
      >
        {activeFeedFilterPills
          .filter((pill) => !excludedIds.includes(pill.id ?? ''))
          .map((pill) => {
            const removePill = async (): Promise<void> => {
              const newFilters = await removeFilter(pill.filter)
              resetOptions(newFilters)
            }
            return (
              <Pill
                key={pill.name}
                name={pill.name}
                onClickRemove={removePill}
                filterType={pill.filter.type}
              />
            )
          })}
      </XStack>
    )
  )
}

export default FeedFilterBarPillGroup
