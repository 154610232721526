import React, { useContext, useEffect } from 'react'
import isNil from 'lodash.isnil'
import { FilterOverlayContext } from '@centrito/app/components/Feed/FilterBar/Overlay/context'
import type { FilterOverlay } from '@centrito/app/components/Feed/FilterBar/Overlay/context/types'
import { BrandsByCategoriesSheet } from '@centrito/app/features/shopper/home/components/BrandsSheet'
import { usePathname } from '@centrito/app/hooks/usePathname'

interface CategoryGroupProps {
  options: FilterOverlay.OptionItem[] | undefined
}

export const BrandGroup: React.FC<CategoryGroupProps> = ({ options }) => {
  const { setIsVisibleOverlay } = useContext(FilterOverlayContext)
  const pathname = usePathname()

  useEffect(() => {
    if (pathname.startsWith('/products/b')) {
      setIsVisibleOverlay(false)
    }
  }, [pathname, setIsVisibleOverlay])

  if (isNil(options)) {
    return null
  }

  return (
    <BrandsByCategoriesSheet
      options={options}
      isOnlyContent={true}
      setIsOpen={setIsVisibleOverlay}
    />
  )
}
