import * as React from 'react'
import type { SVGProps } from 'react'

const SmillingSamSamLogo = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} fill="none" {...props}>
    <mask
      id="a"
      width={36}
      height={35}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path
        fill="#D9D9D9"
        d="M17.519 35c9.665 0 17.5-7.835 17.5-17.5S27.184 0 17.519 0 .019 7.835.019 17.5 7.854 35 17.519 35Z"
      />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#4747ED"
        d="M17.519 35c9.665 0 17.5-7.835 17.5-17.5S27.184 0 17.519 0 .019 7.835.019 17.5 7.854 35 17.519 35Z"
      />
      <path
        fill="#FFF6EE"
        d="M29.341 15.908v6.438a4.892 4.892 0 0 1-4.882 4.684 4.864 4.864 0 0 1-3.457-1.433h-.008a4.878 4.878 0 0 1-6.858-.065h-.008a4.887 4.887 0 0 1-8.405-3.186h-.017V15.99c0-4.56 3.811-8.24 8.372-7.977.659.041 1.3.156 1.902.346.058.024.123.04.19.057.427.132.88.198 1.35.198.436 0 .855-.058 1.259-.173.123-.033.247-.074.362-.115a7.94 7.94 0 0 1 2.272-.33 7.914 7.914 0 0 1 7.912 7.912h.016Z"
      />
      <path
        fill="#011627"
        d="M21.406 17.192a2.555 2.555 0 0 1-.7-1.39c-.05-.289.033-.437.263-.478.24-.042.354.066.42.362.19.897.774 1.383 1.564 1.3.774-.073 1.342-.56 1.548-1.317.017-.049.033-.098.05-.156.148-.14.312-.198.494-.082.189.115.164.313.123.485-.14.535-.428.972-.906 1.31.23.106.445.115.659.156.288.066.428.23.379.452-.041.19-.247.297-.519.24a9.285 9.285 0 0 0-2.182-.248 9.2 9.2 0 0 0-1.901.19c-.198.04-.363.008-.461-.181-.107-.206.016-.445.28-.494.271-.058.551-.091.889-.149ZM16.114 17.349c.198.024.322.123.387.32.107.347.338.536.601.528.272-.008.486-.214.593-.544.124-.378.198-.42.576-.329a.413.413 0 0 1 .132.37c-.057.602-.6 1.137-1.202 1.186-.634.05-1.185-.338-1.366-.963-.066-.255-.033-.461.28-.568ZM26.527 17.637c-.14-.404-.033-.56.345-.56.47-.008.93.066 1.392.132.214.033.304.18.28.387-.025.19-.157.28-.338.255-.485-.066-.963-.132-1.449-.124-.074-.008-.148-.057-.23-.09ZM28.13 15.406c.009.173-.082.288-.238.337-.387.116-.774.256-1.145.404-.197.082-.37.066-.477-.148-.082-.173.016-.354.247-.461a9.949 9.949 0 0 1 1.169-.428c.239-.075.436.065.444.296ZM10.909 17.563c.806-.009 1.621-.058 2.395.14a1.6 1.6 0 0 0 .264-.873v-1.169c0-.89-.717-1.605-1.606-1.605-.889 0-1.605.716-1.605 1.605v1.169c0 .305.082.585.23.823a.705.705 0 0 1 .322-.09Z"
      />
      <path
        fill="#FFF6EE"
        d="M11.39 16.278a.495.495 0 0 1-.494-.494v-.411c0-.272.223-.494.494-.494.272 0 .494.222.494.494v.411a.49.49 0 0 1-.494.494Z"
      />
      <path
        fill="#011627"
        d="M10.54 17.332c-.33.058-.618.091-.89.149-.263.057-.387.288-.28.494.1.189.264.222.461.18a9.256 9.256 0 0 1 1.902-.189 9.29 9.29 0 0 1 2.182.247c.271.066.477-.04.518-.238.05-.223-.099-.396-.378-.453-.214-.05-.429-.058-.66-.157a9.494 9.494 0 0 0-2.856-.032Z"
      />
    </g>
  </svg>
)
export default SmillingSamSamLogo
