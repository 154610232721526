import React from 'react'
import type { FilterOverlay } from '@centrito/app/components/Feed/FilterBar/Overlay/context/types'
import { XStack, YStack } from '@centrito/ui/src'
import { MerchantOptionItem } from './Item'

interface MerchantGroupProps {
  options: FilterOverlay.OptionItem[]
}

export const MerchantGroup: React.FC<MerchantGroupProps> = ({ options }) => {
  return (
    <YStack
      flex={1}
      paddingVertical={15}
      paddingLeft={30}
      paddingRight={10}
      alignItems="center"
      justifyContent="center"
    >
      <XStack flexWrap="wrap" height="100%" justifyContent="flex-start" rowGap={10}>
        {options.map((optionItem) => (
          <MerchantOptionItem key={optionItem.name} optionItem={optionItem} />
        ))}
      </XStack>
    </YStack>
  )
}
