import React, { useCallback, useEffect, useRef, useState } from 'react'
import type { ProductExtendedPublicComposite } from '@centrito/api/nest/catalog/products/domain/composites/product-extended.composite'
import FilterBar from '@centrito/app/components/Feed/FilterBar'
import { Footer } from '@centrito/app/components/Feed/ProductGroup/Footer'
import { ProductDisplay } from '@centrito/app/components/ProductDisplay/ProductDisplay'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'
import type { FeedType } from '@centrito/app/utils/services/analytics/posthog/utils/FeedType'
import { YStack } from '@centrito/ui/src'
import BaseGrid from '@centrito/ui/src/components/BaseGrid'

const FilterBarMemo = React.memo(FilterBar)

interface ImpressionTrackingProps {
  productId: string
  productName: string
  position: number
  feedType: FeedType
}

const useImpressionTracking = ({
  productId,
  productName,
  position,
  feedType,
}: ImpressionTrackingProps): React.RefObject<HTMLDivElement> => {
  const productRef = useRef<HTMLDivElement>(null)
  const hasBeenViewed = useRef(false)

  useEffect(() => {
    const currentProduct = productRef.current
    if (!currentProduct) return

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !hasBeenViewed.current) {
          posthogClient.captureProductImpression(feedType, productId, productName, position)
          hasBeenViewed.current = true
        }
      },
      { threshold: 1 }, // 100% of the item is visible
    )

    observer.observe(currentProduct)

    return (): void => {
      if (currentProduct) {
        observer.unobserve(currentProduct)
      }
    }
  }, [productId, productName, position, feedType])

  return productRef
}

interface ProductWithImpressionProps {
  product: ProductExtendedPublicComposite
  index: number
  feedType: FeedType
}

const ProductWithImpressionComponent: React.FC<ProductWithImpressionProps> = ({
  product,
  index,
  feedType,
}) => {
  const ref = useImpressionTracking({
    productId: product.product.id,
    productName: product.product.name,
    position: index,
    feedType,
  })

  return (
    <div ref={ref}>
      <ProductDisplay
        key={'product-display-feed-' + product.product.id}
        product={product}
        feedType={feedType}
        index={index}
      />
    </div>
  )
}

const ProductWithImpression = React.memo(ProductWithImpressionComponent)

export interface FeedProductsGroupProps {
  products: ProductExtendedPublicComposite[]
  loadMoreProducts: () => void
  hasMoreProducts: boolean
  feedType: FeedType
  isShowingFilters?: boolean
  productsCount?: number
  header?: JSX.Element
}

const FeedProductsGroup: React.FC<FeedProductsGroupProps> = ({
  products,
  loadMoreProducts,
  hasMoreProducts,
  feedType,
  isShowingFilters = true,
  productsCount = undefined,
  header = undefined,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const observerRef = useRef<IntersectionObserver | null>(null)
  const loadTriggerRef = useRef<HTMLDivElement | null>(null)
  const lastFetchedCountRef = useRef(0)
  const containerRef = useRef<HTMLDivElement>(null)

  const handleLoadMore = useCallback(() => {
    if (hasMoreProducts && !isLoading) {
      setIsLoading(true)
      loadMoreProducts()
    }
  }, [hasMoreProducts, isLoading, loadMoreProducts])

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0]
      if (target.isIntersecting) {
        handleLoadMore()
      }
    },
    [handleLoadMore],
  )

  const handleScroll = useCallback(() => {
    if (!containerRef.current || !loadTriggerRef.current) return

    const containerBottom = containerRef.current.getBoundingClientRect().bottom
    const triggerTop = loadTriggerRef.current.getBoundingClientRect().top

    if (containerBottom >= triggerTop) {
      handleLoadMore()
    }
  }, [handleLoadMore])

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    }

    observerRef.current = new IntersectionObserver(handleObserver, options)

    window.addEventListener('scroll', handleScroll)
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleObserver, handleScroll])

  useEffect(() => {
    setIsLoading(false)
    if (loadTriggerRef.current && observerRef.current) {
      observerRef.current.disconnect()
      observerRef.current.observe(loadTriggerRef.current)
    }
  }, [products])

  const renderProduct = (product: ProductExtendedPublicComposite, index: number) => {
    return (
      <ProductWithImpression
        key={`product-with-impression-${index}`}
        product={product}
        index={index}
        feedType={feedType}
      />
    )
  }

  const generateChunks = (items: ProductExtendedPublicComposite[]): JSX.Element[][] => {
    return items.reduce((acc: JSX.Element[][], _, index, array) => {
      if (index % 2 === 0) {
        acc.push(
          array.slice(index, index + 2).map((product, i) => renderProduct(product, index + i)),
        )
      }
      return acc
    }, [])
  }

  const chunks = generateChunks(products)
  const newItemsCount = products.length - lastFetchedCountRef.current
  const newChunksCount = Math.ceil(newItemsCount / 2)
  const triggerPoint = chunks.length - Math.floor(newChunksCount / 2)

  useEffect(() => {
    lastFetchedCountRef.current = products.length
  }, [products])

  return (
    <div ref={containerRef}>
      {isShowingFilters && (
        <YStack>
          {header}
          <FilterBarMemo productsCount={productsCount} />
        </YStack>
      )}
      <BaseGrid stackProps={{ alignItems: 'center' }}>
        {chunks.map((chunk, chunkIndex) => (
          <React.Fragment key={`product-feed-grid-${chunkIndex}`}>
            <BaseGrid.Row>
              {chunk.map((item, itemIndex) => (
                <BaseGrid.Column
                  stackProps={{
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  key={chunkIndex * 2 + itemIndex}
                >
                  {item}
                </BaseGrid.Column>
              ))}
            </BaseGrid.Row>
            {chunkIndex === triggerPoint && (
              <div ref={loadTriggerRef} style={{ height: '1px', width: '100%' }} />
            )}
          </React.Fragment>
        ))}
      </BaseGrid>
      {<Footer hasMoreProducts={hasMoreProducts} numProducts={products.length} />}
    </div>
  )
}

export default FeedProductsGroup
