import React from 'react'
import { SolitoImage as Image } from 'solito/image'
import { SmallDiscountTag } from '@centrito/ui/src/components/outlet/SmallDiscountTag'

export interface TottoSmallDiscountTagProps {
  discount: number
}

export const TottoSmallDiscountTag: React.FC<TottoSmallDiscountTagProps> = ({ discount }) => {
  return (
    <SmallDiscountTag
      logo={
        <Image
          src="https://odcmbzocropvzmbsarer.supabase.co/storage/v1/object/public/merchant-profile-picture/totto.png"
          alt="Totto Logo"
          width={40}
          height={14}
          contentFit="fill"
        />
      }
      discount={discount}
    />
  )
}
