import React from 'react'
import { CircleUser, ShoppingCart } from '@tamagui/lucide-icons'
import isUndefined from 'lodash.isundefined'
import { Stack, XStack, YStack } from '@centrito/ui/src'
import { BoldTextTertiary, DefaultTextPrimary } from '@centrito/ui/src/components/text'

export interface MerchantCardProps {
  name: string
  orderCount?: number
}

const MerchantCard: React.FC<MerchantCardProps> = ({ name, orderCount = undefined }) => {
  return (
    <XStack alignItems="center" paddingHorizontal={20}>
      <Stack padding={20}>
        <CircleUser color="$samsamBlue" width={45} height={45} />
      </Stack>
      <YStack>
        <BoldTextTertiary fontSize={20} color="#333333">
          {name}
        </BoldTextTertiary>
        {!isUndefined(orderCount) && orderCount > 50 && (
          <XStack alignItems="center" paddingVertical={5}>
            <ShoppingCart size={20} color="#7f7f7f" />
            <DefaultTextPrimary marginLeft={5} fontSize={15}>
              {orderCount}
            </DefaultTextPrimary>
            <DefaultTextPrimary marginLeft={4} color="#7f7f7f" fontSize={15}>
              ventas
            </DefaultTextPrimary>
          </XStack>
        )}
      </YStack>
    </XStack>
  )
}

export default MerchantCard
