import * as React from 'react'

const RaySvg = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={9} height={14} fill="none" {...props}>
    <path
      fill="#011627"
      stroke="#011627"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M2.555.79H7.71L5.109 5.476h3.07l-6 7.734 1.407-5.39H.82L2.555.788Z"
    />
  </svg>
)
export default RaySvg
