import React, { type SVGProps } from 'react'

export const SmallWhiteBox: React.FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={54} height={19} fill="none" {...props}>
      <path
        fill="#fff"
        stroke="#CECECE"
        d="M.5 5A4.5 4.5 0 0 1 5 .5h43.337c2.917 0 5.063 2.733 4.371 5.567l-2.195 9A4.5 4.5 0 0 1 46.14 18.5H5A4.5 4.5 0 0 1 .5 14V5Z"
      />
    </svg>
  )
}
