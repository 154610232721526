import React, { useContext } from 'react'
import isNil from 'lodash.isnil'
import { createParam } from 'solito'
import { GetProductsFilterType } from '@centrito/api/shared/enums'
import { FilterOverlayContext } from '@centrito/app/components/Feed/FilterBar/Overlay/context'
import { Sheet, Spacer } from '@centrito/ui/src'
import { OverlayBody as Body, FilterKind } from './Body'
import { OverlayFooter as Footer } from './Footer'
import { OverlayHeader as Header } from './Header'
import { FilterOverlayContextReexposer } from './Reexposer'

const { useParams } = createParam()

interface OverlaySheetProps {
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const determineKind = (params: Record<string, string> | undefined): FilterKind => {
  if (!isNil(params?.categoryNodesPrefix)) {
    return FilterKind.CATEGORY
  }

  if (!isNil(params?.supplier)) {
    return FilterKind.SUPPLIER
  }

  if (!isNil(params?.brandId)) {
    return FilterKind.BRAND
  }

  return FilterKind.DEFAULT
}

const _OverlaySheet: React.FC<OverlaySheetProps> = ({ isVisible, setIsVisible }) => {
  const { params } = useParams()

  const value = useContext(FilterOverlayContext)
  const { currentFilterDrawer } = value

  return (
    <Sheet
      open={isVisible}
      onOpenChange={(): void => setIsVisible(!isVisible)}
      snapPoints={[
        isNil(currentFilterDrawer) || currentFilterDrawer === GetProductsFilterType.PRICE_RANGE
          ? 60
          : 90,
      ]}
      modal
    >
      <Sheet.Overlay alignSelf="center">
        <Spacer backgroundColor="#00000010" size="100%" zIndex={900} />
      </Sheet.Overlay>
      <Sheet.Frame
        overflow="scroll"
        backgroundColor="$pureWhite"
        borderRadius="$1"
        maxWidth={500}
        width="$full"
        marginHorizontal="auto"
        alignSelf="center"
      >
        <FilterOverlayContextReexposer value={value}>
          <Header />
          <Body kind={determineKind(params)} />
          <Footer />
        </FilterOverlayContextReexposer>
      </Sheet.Frame>
    </Sheet>
  )
}

export const OverlaySheet = React.memo(_OverlaySheet)
