import React from 'react'
import { Stack, XStack, styled } from '@centrito/ui/src'
import RayIcon from '@centrito/ui/src/components/svgs/assets/Ray'
import { DetailText3 } from '@centrito/ui/src/components/text/new/detail/DetailText3'

export interface FlashSaleProps {
  productDiscount: number
}

const Container = styled(Stack, {
  backgroundColor: '#FFE600',
  alignItems: 'center',
  justifyContent: 'center',
  paddingVertical: 2.5,
  paddingHorizontal: 5,
  margin: 5,
  borderRadius: 2,
})

export const FlashSale: React.FC<FlashSaleProps> = ({ productDiscount }) => {
  return (
    <Container>
      <XStack gap={2}>
        <RayIcon />
        <DetailText3 color="$darkGray" fontSize={10}>
          -{productDiscount}%
        </DetailText3>
      </XStack>
    </Container>
  )
}
