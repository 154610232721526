import { useContext, useEffect, useState } from 'react'
import isNil from 'lodash.isnil'
import { GetProductsFilterType as FilterType } from '@centrito/api/shared/enums'
import { FilterOverlayContext } from '@centrito/app/components/Feed/FilterBar/Overlay/context'
import { FILTER_OVERLAY_OPTION_GROUP } from '@centrito/app/components/Feed/FilterBar/Overlay/context/constants'
import type { FilterOverlay } from '@centrito/app/components/Feed/FilterBar/Overlay/context/types'

const useAvailableFeedFilterPills = () => {
  const { options } = useContext(FilterOverlayContext)
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<Record<string, FilterOverlay.OptionGroupConfig> | undefined>(
    undefined,
  )

  useEffect(() => {
    if (isNil(options)) {
      setIsLoading(true)
      setData(undefined)
      return
    }

    const availableFilterPills = Object.entries(FILTER_OVERLAY_OPTION_GROUP).filter(([key]) => {
      const filterType = parseInt(key) as FilterType

      if (filterType === FilterType.BRAND_ID) {
        return Array.isArray(options[filterType]) && options[filterType]!.length > 0
      }
      return true
    })

    const availableFilterPillsObject = Object.fromEntries(availableFilterPills) as Record<
      string,
      FilterOverlay.OptionGroupConfig
    >

    setData(availableFilterPillsObject)
    setIsLoading(false)
  }, [options])

  return {
    data,
    isLoading,
  }
}

export default useAvailableFeedFilterPills
