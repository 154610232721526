import React from 'react'
import isNil from 'lodash.isnil'
import { formatPrice } from '@centrito/app/utils/products'
import { BodyText2, DetailText3 } from '@centrito/ui/src/components/text/new'

export interface ProductPriceProps {
  priceRange: {
    low: number | undefined
    high: number | undefined
  }
  productDiscount: number
  isShowingDiscount: boolean
  retailPrice: number
}

export const ProductPrice: React.FC<ProductPriceProps> = ({
  priceRange,
  productDiscount,
  isShowingDiscount,
  retailPrice,
}) => {
  const lowPrice =
    !isNil(priceRange.low) && retailPrice > priceRange.low && priceRange.low - productDiscount != 0
      ? formatPrice(priceRange.low - productDiscount)
      : formatPrice(retailPrice - productDiscount)
  const highPrice =
    !isNil(priceRange.high) && !isNil(priceRange.low) && priceRange.high !== priceRange.low
      ? `-${formatPrice(priceRange.high - productDiscount)}`
      : null
  const discountedPrice = !isNil(priceRange.high)
    ? priceRange.high >= retailPrice
      ? formatPrice(priceRange.high)
      : formatPrice(retailPrice)
    : '-'
  return (
    <React.Fragment>
      <BodyText2
        fontSize={priceRange.high !== priceRange.low ? 12 : 14}
        userSelect="none"
        color={productDiscount != 0 ? '$brightRed' : '$deepNavy'}
      >
        {lowPrice}
        {highPrice}
      </BodyText2>
      {isShowingDiscount && (
        <DetailText3
          color={'$midToneGray'}
          fontSize={11}
          userSelect="none"
          textDecorationLine="line-through"
          textAlign="left"
          marginLeft={6}
        >
          {discountedPrice}
        </DetailText3>
      )}
    </React.Fragment>
  )
}
