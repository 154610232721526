import React from 'react'
import { Eye, EyeOff, X } from '@tamagui/lucide-icons'
import isNil from 'lodash.isnil'
import type {
  NativeSyntheticEvent,
  TextInput as RNTextInput,
  TextInputChangeEventData,
  TextInputIOSProps,
  TextInputKeyPressEventData,
  TextInputProps,
} from 'react-native'
import { Input, type InputProps, Label, Stack, Text, XStack, YStack } from 'tamagui'
import { DefaultTextPrimary, DefaultTextSecondary } from '@centrito/ui/src/components/text'

export interface BaseInputProps extends InputProps {
  name: string | undefined
  iconLeft?: JSX.Element
  label?: string
  hasShadow?: boolean
  placeholder?: string
  placeholderTextColor?: string
  isNumeric?: boolean
  prepend?: string
  backgroundColor?: string
  borderRadius?: number
  textContentType?: TextInputIOSProps['textContentType']
  keyBoardType?: TextInputProps['keyboardType']
  onChange?: (e: NativeSyntheticEvent<TextInputChangeEventData>) => void
  onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void
  onFocus?: () => void
  onSubmitEditing?: () => void
  setTextValue?: ((value: React.SetStateAction<string>) => void) | ((value: string) => void)
  isDisabled?: boolean
  isValid?: boolean
  error?: string
  textValue?: string
  maxLength?: number
  isShort?: boolean
  overriddenHeight?: number
  forceCenter?: boolean
  inputMode?: TextInputProps['inputMode']
  isRequired?: boolean
}

export const BaseInput = React.forwardRef<RNTextInput, BaseInputProps>(
  (
    {
      name,
      iconLeft,
      label,
      hasShadow = true,
      isNumeric = false,
      textValue = '',
      prepend,
      isDisabled,
      setTextValue = (): null => null,
      onChange = (): null => null,
      onKeyPress = (): null => null,
      onBlur = (): null => null,
      onFocus = (): null => null,
      onSubmitEditing = (): null => null,
      maxLength = 50,
      placeholder = '',
      placeholderTextColor = '#C7C7C7',
      backgroundColor = '#f5f5f5',
      borderRadius = 8,
      textContentType = 'none',
      keyBoardType = 'default',
      isValid = true,
      error,
      autoCapitalize = undefined,
      isShort = false,
      overriddenHeight = undefined,
      forceCenter = undefined,
      inputMode = undefined,
      isRequired = false,
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = React.useState(false)
    const [isSecureTextEntry, setIsSecureTextEntry] = React.useState(textContentType === 'password')

    const handleIconClick = (): void => {
      if (ref && 'current' in ref && ref.current) {
        ref.current.focus()
      }
    }

    const handleChange = (e: NativeSyntheticEvent<TextInputChangeEventData>): void => {
      if (isNumeric) {
        const numValue = e.nativeEvent.text.replace(/[^0-9]/g, '')
        onChange({ ...e, nativeEvent: { ...e.nativeEvent, text: numValue } })
      } else {
        onChange(e)
      }
    }

    return (
      <Stack
        ref={ref}
        width="100%"
        onPress={(): void => {
          if (ref && 'current' in ref && ref.current) {
            ref.current.focus()
          }
        }}
      >
        <XStack
          borderWidth={1}
          borderColor={isValid ? (isFocused ? '#808080' : backgroundColor) : '#e21a1a'}
          backgroundColor={backgroundColor}
          paddingHorizontal={16}
          borderRadius={borderRadius}
          height={overriddenHeight ?? (label ? (isShort ? 66 : 91) : 40)}
          justifyContent={forceCenter ? 'center' : 'space-between'}
          alignItems="center"
          width="100%"
          {...(hasShadow
            ? {
                elevation: 10,
                shadowOffset: { width: 0, height: 4 },
                shadowRadius: 18,
                shadowColor: '#0000001E',
              }
            : {})}
        >
          <YStack flex={1}>
            {!!label && (
              <Label
                htmlFor={name}
                fontFamily="$poppinsFont"
                color={isValid ? '#808080' : '#e21a1a'}
                fontSize={12}
                lineHeight={16}
                asChild
              >
                <Text>
                  {label}
                  {isRequired && <Text color="#FF004F"> *</Text>}
                </Text>
              </Label>
            )}
            <XStack>
              {iconLeft && (
                <XStack
                  alignItems="center"
                  onPress={handleIconClick}
                  paddingRight={16}
                  zIndex={999}
                >
                  {iconLeft}
                </XStack>
              )}
              <DefaultTextPrimary color="#808080" fontSize={16} zIndex={999}>
                {prepend}
              </DefaultTextPrimary>
              <Input
                ref={ref}
                id={name}
                flex={1}
                fontFamily="$poppinsFont"
                placeholder={placeholder}
                placeholderTextColor={placeholderTextColor}
                textContentType={textContentType}
                letterSpacing={0}
                keyboardType={keyBoardType}
                backgroundColor={backgroundColor}
                borderRadius={borderRadius}
                width="100%"
                height={28}
                secureTextEntry={isSecureTextEntry}
                onFocus={(): void => {
                  setIsFocused(true)
                  onFocus()
                }}
                onBlur={(e): void => {
                  setIsFocused(false)
                  onBlur(e)
                }}
                onSubmitEditing={(): void => {
                  setIsFocused(false)
                  onSubmitEditing()
                }}
                onChange={handleChange}
                onKeyPress={onKeyPress}
                onChangeText={(text): void => setTextValue(text)}
                disabled={isDisabled}
                maxLength={maxLength}
                borderWidth={0}
                focusStyle={{
                  borderWidth: 0,
                  outlineStyle: 'none',
                }}
                paddingLeft={isNil(prepend) ? 0 : 13}
                fontSize={16}
                color="#000000"
                autoCapitalize={keyBoardType === 'email-address' ? 'none' : autoCapitalize}
                value={textValue}
                inputMode={inputMode}
              />
            </XStack>
          </YStack>
          {!!textValue &&
            (textContentType === 'password' ? (
              <XStack
                zIndex={10}
                // eslint-disable-next-line react/display-name
                onPress={(): void => setIsSecureTextEntry(!isSecureTextEntry)}
              >
                {isSecureTextEntry ? (
                  <Eye width={24} height={24} />
                ) : (
                  <EyeOff width={24} height={24} />
                )}
              </XStack>
            ) : (
              <XStack zIndex={10} onPress={(): void => setTextValue('')}>
                <X width={24} height={24} />
              </XStack>
            ))}
        </XStack>
        {!isValid && (
          <DefaultTextSecondary color="#e21a1a" fontSize={12}>
            {error}
          </DefaultTextSecondary>
        )}
      </Stack>
    )
  },
)

BaseInput.displayName = 'BaseInput'

export default BaseInput
