import React from 'react'
import { Inbox } from '@tamagui/lucide-icons'
import { Spacer, YStack } from 'tamagui'
import { DefaultTextPrimary } from '@centrito/ui/src/components/text'

export interface FeedViewEmptyProps {
  message?: string
}

const FeedViewEmpty: React.FC<FeedViewEmptyProps> = ({
  message = 'No se encontraron productos',
}) => (
  <YStack width="100%" display="flex" justifyContent="center" alignItems="center">
    <Spacer size="$16" />
    <Inbox size="$6" color="#CCCCCC" strokeWidth={1} />
    <Spacer size="$1" />
    <DefaultTextPrimary color="#CCCCCC">{message}</DefaultTextPrimary>
  </YStack>
)

export default FeedViewEmpty
