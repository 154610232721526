import React, { forwardRef } from 'react'
import { Check as CheckIcon } from '@tamagui/lucide-icons'
import { Checkbox, Label, XStack } from 'tamagui'

export interface SimpleCheckboxProps {
  isChecked: boolean | undefined
  onChange?: (checked: boolean) => void | undefined
  id?: string
  bordered?: boolean
  label?: boolean
  paddingRight?: number
  color?: string
  children?: React.ReactNode
}

export const SimpleCheckbox = forwardRef<HTMLDivElement, SimpleCheckboxProps>(
  (
    {
      children,
      isChecked,
      onChange = undefined,
      id,
      bordered = false,
      label = true,
      paddingRight = 0,
      color = '#FF004F',
    },
    ref,
  ) => {
    const parsedId = id ?? (children ? 'checkbox-' + children.toString() : undefined)

    return (
      <XStack alignItems="center" gap="$2" paddingRight={paddingRight}>
        <Checkbox
          ref={ref}
          backgroundColor={isChecked ? color : undefined}
          checked={isChecked}
          onCheckedChange={onChange}
          id={parsedId}
          size={24}
          height={24}
          width={24}
          borderWidth={bordered ? 2 : undefined}
          borderColor={bordered ? color : undefined}
          borderStyle={bordered ? 'solid' : undefined}
          focusStyle={
            bordered ? { borderWidth: 2, borderColor: color, borderStyle: 'solid' } : undefined
          }
          hoverStyle={
            bordered ? { borderWidth: 2, borderColor: color, borderStyle: 'solid' } : undefined
          }
        >
          <Checkbox.Indicator>
            <CheckIcon color="white" />
          </Checkbox.Indicator>
        </Checkbox>
        {label ? (
          <Label fontFamily="$poppinsFont" htmlFor={id}>
            {children}
          </Label>
        ) : (
          children
        )}
      </XStack>
    )
  },
)

SimpleCheckbox.displayName = 'SimpleCheckbox'
