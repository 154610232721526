import React from 'react'
import { ChevronDown } from '@tamagui/lucide-icons'
import isNil from 'lodash.isnil'
import { createParam } from 'solito'
import { GetProductsFilterType } from '@centrito/api/shared/enums'
import { SIZED_CATEGORIES } from '@centrito/app/utils/components/Feed'
import useAvailableFeedFilterPills from '@centrito/app/utils/hooks/useAvailableFeedFilterPills'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'
import { ScrollView, XStack } from '@centrito/ui/src'
import FilterBarOnlySkeleton from '@centrito/ui/src/components/skeletons/FilterBarOnlySkeleton'
import { BodyText3 } from '@centrito/ui/src/components/text/new/body/BodyText3'

export type MerchantInfo = {
  name?: string
  orderCount?: number
}

export interface FeedFilterBarRibbonProps {
  onFilterPress(key?: GetProductsFilterType): void
}

const { useParams } = createParam()

const FeedFilterBarRibbon: React.FC<FeedFilterBarRibbonProps> = ({ onFilterPress }) => {
  const { params } = useParams()
  const { data: availableFilters, isLoading } = useAvailableFeedFilterPills()

  if (isLoading || !availableFilters) {
    return <FilterBarOnlySkeleton />
  }

  if (!isLoading && !availableFilters) {
    return null
  }

  const handleFilterPress = (filterType?: GetProductsFilterType): void => {
    if (!isNil(filterType)) {
      const filterTypeName = GetProductsFilterType[filterType] as keyof typeof GetProductsFilterType
      posthogClient.captureCustomEvent('shopper_product_list_filter_pressed', {
        filterType: filterTypeName,
      })
    }
    onFilterPress(filterType)
  }

  return (
    <ScrollView
      horizontal
      backgroundColor={'$pureWhite'}
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={{
        paddingHorizontal: 15,
        alignItems: 'center',
      }}
      minHeight={50}
      paddingVertical={15}
    >
      {Object.entries(availableFilters)
        .filter(([key]) => {
          const filterType = parseInt(key) as GetProductsFilterType

          const categoryNodesPrefix = params?.categoryNodesPrefix

          return (
            filterType !== GetProductsFilterType.CATEGORY &&
            (filterType !== GetProductsFilterType.SIZE ||
              isNil(categoryNodesPrefix) ||
              SIZED_CATEGORIES.some((category) => categoryNodesPrefix.startsWith(category))) &&
            (isNil(params?.brandId) || filterType !== GetProductsFilterType.BRAND_ID) &&
            (isNil(params?.merchant) || filterType !== GetProductsFilterType.SUPPLIER)
          )
        })
        .map(([key, data], index, array) => {
          const filterType = parseInt(key) as GetProductsFilterType
          return (
            <XStack
              borderColor="$Gray2_5"
              borderWidth={1}
              alignItems="center"
              borderRadius={24}
              paddingVertical={5}
              paddingHorizontal={10}
              key={`${key}-filter-ribbon`}
              onPress={(): void => handleFilterPress(filterType)}
              marginRight={index < array.length - 1 ? 7 : 0}
            >
              <BodyText3 marginRight={10} color="$Gray2_5">
                {data.optionGroupName}
              </BodyText3>
              <ChevronDown color="$Gray2_5" size={14} />
            </XStack>
          )
        })}
    </ScrollView>
  )
}

export default FeedFilterBarRibbon
