import React from 'react'
import { CircleFeedButton } from '@centrito/ui/src/components/button/CircleFeedButton'

export interface SubCategoryTreeProps {
  isShowingAll: boolean
  width?: number
  height?: number
}

const SeeMoreOrLessButton: React.FC<SubCategoryTreeProps> = ({ isShowingAll }) => {
  return (
    <CircleFeedButton
      imageSrc={isShowingAll ? 'CircleArrowUp' : 'CircleArrowDown'}
      label={isShowingAll ? 'Ver menos' : 'Ver más'}
    />
  )
}

export default SeeMoreOrLessButton
