import { useContext } from 'react'
import isUndefined from 'lodash.isundefined'
import type {
  CategoryPublic,
  MerchantBasicPublic,
} from '@centrito/api/nest/platform/database/domain'
import type { BrandPublic } from '@centrito/api/nest/platform/database/domain/entities/brand'
import type { GetProducts } from '@centrito/api/shared/catalog'
import { GetProductsFilterType } from '@centrito/api/shared/enums'
import { trpc } from '@centrito/app/api/trpc'
import { FilterOverlayContext } from '@centrito/app/components/Feed/FilterBar/Overlay/context'
import { getColorNameFromKey } from '@centrito/app/utils/components/FeedView/ColorBubble'
import { useFiltersQuery } from '@centrito/app/utils/hooks/useFeedProducts/useFiltersQuery'
import { formatPrice } from '@centrito/app/utils/products'

const isOverlayFilter = (filterType: string): boolean =>
  [
    GetProductsFilterType.CATEGORY,
    GetProductsFilterType.COLOR,
    GetProductsFilterType.SIZE,
    GetProductsFilterType.PRICE_RANGE,
    GetProductsFilterType.SUPPLIER,
    GetProductsFilterType.BRAND_ID,
  ].includes(parseInt(filterType))

export interface ActiveFeedFiltersPill<K extends GetProductsFilterType = GetProductsFilterType> {
  id?: string
  name: string
  filter: GetProducts.FilterItem<K>
}

const getTargetCategory = (
  categories: CategoryPublic[],
  categoryName: string,
): CategoryPublic | undefined => {
  return categories.find((category) => category.nodes === categoryName)
}

const getTargetMerchant = (
  merchants: MerchantBasicPublic[],
  merchantId: string,
): MerchantBasicPublic | undefined => {
  return merchants.find((merchant) => merchant.id === merchantId)
}

const getTargetBrand = (brands: BrandPublic[], brandId: string): BrandPublic | undefined => {
  return brands.find((brand) => brand.id === brandId)
}

const useActiveFeedFilterPills = (): {
  activeFeedFilterPills: ActiveFeedFiltersPill<GetProductsFilterType>[]
} => {
  const { priceRangeBoundaries } = useContext(FilterOverlayContext)
  const { filters } = useFiltersQuery()
  const { data: categories } = trpc.catalog.category.findMany.useQuery(undefined)
  const { data: merchants } = trpc.catalog.merchant.findMany.useQuery(undefined)
  const { data: dataBrands } = trpc.catalog.brand.findManyByCategory.useQuery({
    categoryNodePrefix: filters[GetProductsFilterType.CATEGORY_PREFIX] ?? '',
  })

  const getActiveFeedFilterPills: {
    [K in GetProductsFilterType]?: (...args: any[]) => ActiveFeedFiltersPill[]
  } = {
    [GetProductsFilterType.CATEGORY]: (
      cats: GetProducts.FilterValue[GetProductsFilterType.CATEGORY] | undefined,
    ): ActiveFeedFiltersPill<GetProductsFilterType.CATEGORY>[] => {
      return (
        cats
          ?.map((categoryName) => getTargetCategory(categories ?? [], categoryName))
          .filter((categoryOrUndefined) => !isUndefined(categoryOrUndefined) && categoryOrUndefined)
          .map((categoryOrUndefined) => {
            const category = categoryOrUndefined as CategoryPublic
            return {
              name: category.nodesNamed,
              filter: {
                type: GetProductsFilterType.CATEGORY,
                value: category.nodes,
              },
            }
          }) ?? []
      )
    },
    [GetProductsFilterType.COLOR]: (
      filterValue: GetProducts.FilterValue[GetProductsFilterType.COLOR],
    ): ActiveFeedFiltersPill<GetProductsFilterType.COLOR>[] => {
      return filterValue.map((color) => {
        return {
          name: getColorNameFromKey(color) ?? '',
          filter: {
            type: GetProductsFilterType.COLOR,
            value: color,
          },
        }
      })
    },
    [GetProductsFilterType.SIZE]: (
      filterValue: GetProducts.FilterValue[GetProductsFilterType.SIZE],
    ): ActiveFeedFiltersPill<GetProductsFilterType.SIZE>[] => {
      return filterValue.map((size) => ({
        name: size || '',
        filter: {
          type: GetProductsFilterType.SIZE,
          value: decodeURIComponent(size),
        },
      }))
    },
    [GetProductsFilterType.PRICE_RANGE]: (
      filterValue: GetProducts.FilterValue[GetProductsFilterType.PRICE_RANGE],
    ): ActiveFeedFiltersPill<GetProductsFilterType.PRICE_RANGE>[] => {
      if (
        filterValue.low !== priceRangeBoundaries.low ||
        filterValue.high !== priceRangeBoundaries.high
      ) {
        return [
          {
            name: `Mayor a ${formatPrice(filterValue.low)}, menor a ${formatPrice(
              filterValue.high,
            )}`,
            filter: {
              type: GetProductsFilterType.PRICE_RANGE,
              value: filterValue,
            },
          },
        ]
      }

      return []
    },
    [GetProductsFilterType.SUPPLIER]: (
      filterValue: GetProducts.FilterValue[GetProductsFilterType.SUPPLIER],
    ): ActiveFeedFiltersPill<GetProductsFilterType.SUPPLIER>[] => {
      return filterValue
        .toString()
        .split(',')
        .map((merchantId) => {
          const targetMerchant = getTargetMerchant(merchants ?? [], merchantId)
          return {
            id: merchantId,
            name: `Proveedor: ${targetMerchant?.name}`,
            filter: {
              type: GetProductsFilterType.SUPPLIER,
              value: merchantId,
            },
          }
        })
    },
    [GetProductsFilterType.BRAND_ID]: (
      filterValue: GetProducts.FilterValue[GetProductsFilterType.BRAND_ID],
    ): ActiveFeedFiltersPill<GetProductsFilterType.BRAND_ID>[] => {
      return filterValue
        .toString()
        .split(',')
        .map((brandId) => {
          const targetBrand = getTargetBrand(dataBrands?.brands ?? [], brandId)
          return {
            id: brandId,
            name: `Marca: ${targetBrand?.name}`,
            filter: {
              type: GetProductsFilterType.BRAND_ID,
              value: brandId,
            },
          }
        })
    },
  }

  const activeFeedFilterPills = Object.entries(filters || {})
    .filter(([type]) => isOverlayFilter(type))
    .map(([type, value]) => {
      const fn = getActiveFeedFilterPills[parseInt(type) as GetProductsFilterType]
      return fn ? fn(value) : []
    })
    .flat()

  return { activeFeedFilterPills }
}

export default useActiveFeedFilterPills
