import React from 'react'
import { Stack, XStack, ZStack } from 'tamagui'
import { SmallWhiteBox } from '@centrito/ui/src/components/svgs/outlet/WhiteBox/Small'
import { SmallYellowBox } from '@centrito/ui/src/components/svgs/outlet/YellowBox/Small'
import { FootnoteStandardText } from '@centrito/ui/src/components/text/new/footnote'

export interface SmallDiscountTagProps {
  logo: React.ReactNode
  discount: number
}

const WIDTH = 86
const HEIGHT = 20

const WHITE_BOX_WIDTH = 55

export const SmallDiscountTag: React.FC<SmallDiscountTagProps> = ({ logo, discount }) => {
  return (
    <Stack width={WIDTH} height={HEIGHT}>
      <ZStack>
        <XStack>
          <ZStack width="100%" height="100%">
            <XStack width={WIDTH} justifyContent="flex-end">
              <SmallYellowBox />
            </XStack>
            <XStack
              justifyContent="flex-end"
              alignItems="center"
              width={WIDTH}
              height={HEIGHT}
              paddingRight={7}
            >
              <FootnoteStandardText color="$deepShopperBlack" textAlign="center">
                -{discount}%
              </FootnoteStandardText>
            </XStack>
          </ZStack>
        </XStack>
        <XStack>
          <ZStack width="100%" height="100%">
            <XStack width={WIDTH}>
              <SmallWhiteBox />
            </XStack>
            <XStack
              alignItems="center"
              width={WHITE_BOX_WIDTH}
              height={HEIGHT}
              justifyContent="center"
            >
              {logo}
            </XStack>
          </ZStack>
        </XStack>
      </ZStack>
    </Stack>
  )
}
