export enum FeedType {
  HOME = 'HOME',
  SUBHOME = 'SUBHOME',
  CATEGORY = 'CATEGORY',
  BRAND = 'BRAND',
  MERCHANT = 'MERCHANT',
  LIST = 'LIST',
  PRODUCT = 'PRODUCT',
  SEARCH = 'SEARCH',
  DUMMY = 'DUMMY', // Used in storybook, to avoid metrics pollution
}
