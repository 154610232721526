import React from 'react'
import { BaseButton } from '@centrito/ui/src/components/button/BaseButton'
import { DefaultTextTertiary } from '@centrito/ui/src/components/text'

export interface ApplyFiltersButtonProps {
  onPress?: () => void
  children: React.ReactNode
}

export const ApplyFiltersButton: React.FC<ApplyFiltersButtonProps> = ({ children, onPress }) => {
  return (
    <BaseButton
      height="$4"
      onPress={onPress}
      width="48%"
      borderRadius={11}
      backgroundColor="#4646F5"
    >
      <DefaultTextTertiary fontWeight="500" fontSize={16} color="#ffffff">
        {children}
      </DefaultTextTertiary>
    </BaseButton>
  )
}
