import React, { useContext } from 'react'
import { FilterOverlayContext } from '@centrito/app/components/Feed/FilterBar/Overlay/context'
import type { FilterOverlay } from '@centrito/app/components/Feed/FilterBar/Overlay/context/types'
import { Stack, YStack } from '@centrito/ui/src'
import { DefaultTextTertiary } from '@centrito/ui/src/components/text'

interface SizeOptionItemProps {
  optionItem: FilterOverlay.OptionItem
}

export const SizeOptionItem: React.FC<SizeOptionItemProps> = ({ optionItem }) => {
  const { changeOptionItemIsChecked } = useContext(FilterOverlayContext)
  const { isChecked, name } = optionItem

  return (
    <YStack
      onPress={(): void => changeOptionItemIsChecked(optionItem)}
      paddingHorizontal={10}
      width="25%"
    >
      <Stack
        borderRadius={7}
        backgroundColor={isChecked ? '#4724A3' : '#E9E9EA'}
        height={40}
        alignItems="center"
        justifyContent="center"
      >
        <DefaultTextTertiary
          textAlign="center"
          fontSize={10}
          color={isChecked ? '#FAFAFA' : '#4724A3'}
          ellipsizeMode="tail"
        >
          {name.replace(/_/g, ' ')}
        </DefaultTextTertiary>
      </Stack>
    </YStack>
  )
}
