import React, { type SVGProps } from 'react'

export const SmallYellowBox: React.FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={46} height={19} fill="none" {...props}>
      <path
        fill="#FFD149"
        d="M0 5a5 5 0 0 1 5-5h35.843c3.174 0 5.545 2.92 4.894 6.026l-1.887 9A5 5 0 0 1 38.956 19H5a5 5 0 0 1-5-5V5Z"
      />
    </svg>
  )
}
