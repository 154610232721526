import type { Brand } from '@centrito/db/prisma/generated/zod'
import type {
  CategoryPublic,
  MerchantBasicPublic,
} from '@centrito/api/nest/platform/database/domain'
import type { GetProducts } from '@centrito/api/shared/catalog'
import { trpc } from '@centrito/app/api/trpc'

interface UseRelevantFilterDataResponse {
  relevantMerchants: MerchantBasicPublic[] | undefined
  relevantMerchantsIsFetched: boolean
  relevantBrands: Brand[] | undefined
  relevantBrandsIsFetched: boolean
  relevantPriceRange:
    | {
        pricing: { low: number; high: number }
      }
    | undefined
  relevantPriceRangeIsFetched: boolean
  relevantCategories: CategoryPublic[] | undefined
  relevantCategoriesIsFetched: boolean
  relevantColors: { hex: string; key: string; name: string }[] | undefined
  relevantColorsIsFetched: boolean
  relevantSizes: string[] | undefined
  relevantSizesIsFetched: boolean
}

export const useRelevantFilterData = (
  filters: GetProducts.Filters,
): UseRelevantFilterDataResponse => {
  const relevantFiltersQuerySettings = {
    enabled: Object.keys(filters).length !== 0,
  }

  const { data: relevantMerchants, isFetched: relevantMerchantsIsFetched } =
    trpc.catalog.filters.getMerchants.useQuery({ filters }, relevantFiltersQuerySettings)

  const { data: relevantBrands, isFetched: relevantBrandsIsFetched } =
    trpc.catalog.filters.getBrands.useQuery({ filters }, relevantFiltersQuerySettings)

  const { data: relevantPriceRange, isFetched: relevantPriceRangeIsFetched } =
    trpc.catalog.filters.getPricing.useQuery({ filters }, relevantFiltersQuerySettings)

  const { data: relevantCategories, isFetched: relevantCategoriesIsFetched } =
    trpc.catalog.filters.getCategories.useQuery({ filters }, relevantFiltersQuerySettings)

  const { data: relevantColors, isFetched: relevantColorsIsFetched } =
    trpc.catalog.filters.getColors.useQuery({ filters }, relevantFiltersQuerySettings)

  const { data: relevantSizes, isFetched: relevantSizesIsFetched } =
    trpc.catalog.filters.getSizes.useQuery({ filters }, relevantFiltersQuerySettings)

  return {
    relevantMerchants,
    relevantMerchantsIsFetched,
    relevantBrands,
    relevantBrandsIsFetched,
    relevantPriceRange,
    relevantPriceRangeIsFetched,
    relevantCategories,
    relevantCategoriesIsFetched,
    relevantColors,
    relevantColorsIsFetched,
    relevantSizes,
    relevantSizesIsFetched,
  }
}
