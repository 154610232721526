import React from 'react'
import { SolitoImage as Image } from 'solito/image'
import { SmallDiscountTag } from '@centrito/ui/src/components/outlet/SmallDiscountTag'

export interface LiliPinkSmallDiscountTagProps {
  discount: number
}

export const LiliPinkSmallDiscountTag: React.FC<LiliPinkSmallDiscountTagProps> = ({ discount }) => {
  return (
    <SmallDiscountTag
      logo={
        <Image
          src="https://odcmbzocropvzmbsarer.supabase.co/storage/v1/object/public/merchant-profile-picture/lili-pink%20(1).png"
          alt="LiliPink Logo"
          width={45}
          height={14}
          contentFit="fill"
        />
      }
      discount={discount}
    />
  )
}
