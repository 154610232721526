import { useEffect } from 'react'
import isNil from 'lodash.isnil'
import { type GetProducts } from '@centrito/api/shared/catalog'
import getInitialOptions from './getInitialOptions'
import { type FilterOverlay } from './types'
import { useRelevantFilterData } from './useRelevantFilterData'

interface UseRelevantFilterDependenciesResponse {
  filters: GetProducts.Filters
  priceRangeBoundaries: {
    low: number
    high: number
  }
  setOptions: React.Dispatch<React.SetStateAction<FilterOverlay.Options>>
}

export const useRelevantFilterDependencies = ({
  filters,
  priceRangeBoundaries,
  setOptions,
}: UseRelevantFilterDependenciesResponse): {
  relevantPriceRange:
    | {
        pricing: {
          low: number
          high: number
        }
      }
    | undefined
} => {
  const {
    relevantMerchants,
    relevantMerchantsIsFetched,
    relevantBrands,
    relevantBrandsIsFetched,
    relevantPriceRange,
    relevantPriceRangeIsFetched,
    relevantCategories,
    relevantCategoriesIsFetched,
    relevantColors,
    relevantColorsIsFetched,
    relevantSizes,
    relevantSizesIsFetched,
  } = useRelevantFilterData(filters)

  useEffect(() => {
    if (
      !isNil(relevantCategories) &&
      !isNil(relevantMerchants) &&
      !isNil(relevantColors) &&
      !isNil(relevantBrands) &&
      !isNil(relevantSizes) &&
      !isNil(priceRangeBoundaries) &&
      relevantMerchantsIsFetched &&
      relevantBrandsIsFetched &&
      relevantPriceRangeIsFetched &&
      relevantCategoriesIsFetched &&
      relevantColorsIsFetched &&
      relevantSizesIsFetched
    ) {
      const initialOptions = getInitialOptions(
        filters,
        relevantCategories,
        relevantBrands,
        relevantMerchants,
        relevantColors,
        relevantSizes,
        priceRangeBoundaries,
      )
      setOptions(initialOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    relevantCategories,
    relevantMerchants,
    relevantColors,
    relevantSizes,
    priceRangeBoundaries,
    relevantMerchantsIsFetched,
    relevantPriceRangeIsFetched,
    relevantCategoriesIsFetched,
    relevantColorsIsFetched,
    relevantSizesIsFetched,
  ])

  return { relevantPriceRange }
}
