import React from 'react'
import { SolitoImage as Image } from 'solito/image'
import { SmallDiscountTag } from '@centrito/ui/src/components/outlet/SmallDiscountTag'

export interface OffcorssSmallDiscountTagProps {
  discount: number
}

export const OffcorssSmallDiscountTag: React.FC<OffcorssSmallDiscountTagProps> = ({ discount }) => {
  return (
    <SmallDiscountTag
      logo={
        <Image
          src="https://odcmbzocropvzmbsarer.supabase.co/storage/v1/object/public/merchant-profile-picture/Offcorss_1456_3e08c558fa.webp"
          alt="Offcorss Logo"
          width={45}
          height={13}
          contentFit="fill"
        />
      }
      discount={discount}
    />
  )
}
