import React, { useContext } from 'react'
import { ChevronLeft, X } from '@tamagui/lucide-icons'
import isNil from 'lodash.isnil'
import { FilterOverlayContext } from '@centrito/app/components/Feed/FilterBar/Overlay/context'
import { FILTER_OVERLAY_OPTION_GROUP } from '@centrito/app/components/Feed/FilterBar/Overlay/context/constants'
import { Stack } from '@centrito/ui/src'
import { BoldTextPrimary } from '@centrito/ui/src/components/text'

export const OverlayHeader: React.FC = () => {
  const { currentFilterDrawer, setCurrentFilterDrawer, setIsVisibleOverlay } =
    useContext(FilterOverlayContext)

  return (
    <Stack
      flexDirection="row"
      padding={12}
      justifyContent="space-between"
      alignItems="center"
      paddingVertical={22}
    >
      {!isNil(currentFilterDrawer) && (
        <Stack onPress={(): void => setCurrentFilterDrawer(undefined)} marginRight={8}>
          <ChevronLeft />
        </Stack>
      )}
      <BoldTextPrimary
        fontSize={20}
        marginLeft={!isNil(currentFilterDrawer) ? 0 : '25%'}
        color="#333333"
        textAlign="center"
      >
        {isNil(currentFilterDrawer)
          ? 'Todos los filtros'
          : `Filtrar por ${FILTER_OVERLAY_OPTION_GROUP[
              currentFilterDrawer
            ]?.optionGroupName.toLowerCase()}`}
      </BoldTextPrimary>
      <Stack onPress={(): void => setIsVisibleOverlay(false)}>
        <X size={24} />
      </Stack>
    </Stack>
  )
}
