import React from 'react'
import { XStack, YStack, useWindowDimensions } from '@centrito/ui/src'
import { BaseSkeleton } from '@centrito/ui/src/components/BaseSkeleton'
import FilterBarSkeleton from '@centrito/ui/src/components/skeletons/FilterBarSkeleton'

export interface FeedProductsGridProps {
  isShowingFilters?: boolean
}

const ProductCardSkeleton: React.FC = () => {
  const { width } = useWindowDimensions()
  const responsiveWidth = width < 500 ? width / 2 - 7 : width / 2 - 25
  return (
    <BaseSkeleton
      marginVertical={3}
      height={305}
      width={responsiveWidth}
      flex={1}
      marginHorizontal={3}
      borderRadius={8}
      padding={0}
    />
  )
}

const FeedProductsGroup: React.FC<FeedProductsGridProps> = ({ isShowingFilters = true }) => {
  return (
    <React.Fragment>
      {isShowingFilters && <FilterBarSkeleton />}
      <YStack paddingHorizontal={20} paddingVertical={10}>
        <XStack>
          <ProductCardSkeleton />
          <ProductCardSkeleton />
        </XStack>
        <XStack>
          <ProductCardSkeleton />
          <ProductCardSkeleton />
        </XStack>
        <XStack>
          <ProductCardSkeleton />
          <ProductCardSkeleton />
        </XStack>
        <XStack>
          <ProductCardSkeleton />
          <ProductCardSkeleton />
        </XStack>
      </YStack>
    </React.Fragment>
  )
}

export default FeedProductsGroup
