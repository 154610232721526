import React from 'react'
import { BaseButton } from '@centrito/ui/src/components/button/BaseButton'
import { DefaultTextTertiary } from '@centrito/ui/src/components/text'

export interface ApplyFiltersButtonProps {
  onPress?: () => void
  disabled?: boolean
  children: React.ReactNode
}

export const RemoveFiltersButton: React.FC<ApplyFiltersButtonProps> = ({
  children,
  onPress,
  disabled,
}) => {
  return (
    <BaseButton
      height="$4"
      onPress={onPress}
      width="48%"
      borderRadius={11}
      disabled={disabled}
      backgroundColor={'#ffffff'}
      borderColor={disabled ? '#AAAAAA' : '#3DA794'}
      borderStyle="solid"
      borderWidth={disabled ? 2 : 3}
      hoverStyle={{
        backgroundColor: '#ffffff',
        borderColor: disabled ? '#AAAAAA' : '#3DA794',
      }}
    >
      <DefaultTextTertiary
        fontFamily="$poppinsFont"
        fontWeight="500"
        fontSize={16}
        color={disabled ? '#AAAAAA' : '#3DA794'}
      >
        {children}
      </DefaultTextTertiary>
    </BaseButton>
  )
}
