import { COLOR_LIBRARY } from '@centrito/api/shared/colors'

export const generateColorGradient = (hexes: string[]): string =>
  `linear-gradient( -45deg${hexes.reduce((acumColor, color) => {
    return acumColor + `, ${color} ${100 / hexes.length}%`
  }, '')})`

export const getColorHexFromName = (colorName: string): string =>
  COLOR_LIBRARY.find((color) => {
    return colorName === color.name
  })?.hex || '#FFFFFF'

export const getColorNameFromKey = (colorKey: string): string | undefined =>
  COLOR_LIBRARY.find((color) => {
    return colorKey === color.key
  })?.name

export const getColorKeyFromName = (colorName: string): string | undefined =>
  COLOR_LIBRARY.find((color) => {
    return colorName === color.name
  })?.key
