import React from 'react'
import { Text, type TextProps } from 'tamagui'

export const BodyText3: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontFamily="$poppinsFont" fontSize={12} fontWeight="400" {...rest}>
      {children}
    </Text>
  )
}
