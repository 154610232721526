import React from 'react'
import type { FilterOverlay } from '@centrito/app/components/Feed/FilterBar/Overlay/context/types'
import { XStack, YStack } from '@centrito/ui/src'
import { CategoryOptionItem } from './Item'

interface CategoryGroupProps {
  options: FilterOverlay.OptionItem[]
}

export const CategoryGroup: React.FC<CategoryGroupProps> = ({ options }) => {
  return (
    <YStack flex={1} paddingVertical={15} paddingLeft={30} paddingRight={8}>
      <XStack flexWrap="wrap" height="100%" justifyContent="flex-start" rowGap={10}>
        {options.map((optionItem) => (
          <CategoryOptionItem key={optionItem.name} optionItem={optionItem} />
        ))}
      </XStack>
    </YStack>
  )
}
