import React, { useEffect, useState } from 'react'
import isNil from 'lodash.isnil'
import type { ProductVariantColorData } from '@centrito/api/nest/platform/database/domain'
import { generateColorGradient } from '@centrito/app/utils/components/FeedView/ColorBubble'
import { Circle } from '@centrito/ui/src'

interface VariantsColorBubbleProps {
  variantColors: ProductVariantColorData
  currentIndex: number
  setVariantIndex: React.Dispatch<React.SetStateAction<number>>
}

export const VariantsColorBubble: React.FC<VariantsColorBubbleProps> = ({
  variantColors,
  currentIndex,
  setVariantIndex,
}) => {
  const hexes =
    variantColors.secondary.hex === ''
      ? variantColors.primary.map((color) => color.hex)
      : [variantColors.secondary.hex]
  const [backgroundColor, setBackgroundColor] = useState<string>(
    hexes.length > 1 ? generateColorGradient(hexes) : hexes[0],
  )

  useEffect(() => {
    if (isNil(variantColors)) {
      setBackgroundColor('')
      return
    }

    if (hexes.length > 1) {
      setBackgroundColor(generateColorGradient(hexes))
    } else {
      setBackgroundColor(hexes[0])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantColors])

  return (
    <Circle
      size={14}
      backgroundColor={backgroundColor}
      borderWidth={2}
      borderColor={'$midToneGray'}
      onPress={(): void => (isNil(setVariantIndex) ? undefined : setVariantIndex(currentIndex))}
    />
  )
}
