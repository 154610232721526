import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { PromotionalCampaignDiscountPresentation } from '@prisma/client/react-native'
import isNil from 'lodash.isnil'
import { Pressable } from 'react-native'
import { createParam } from 'solito'
import { useRouter } from 'solito/router'
import PATHS from '@centrito/common/paths'
import type { ProductExtendedPublicComposite } from '@centrito/api/nest/catalog/products/domain/composites/product-extended.composite'
import { GetProductsFilterType } from '@centrito/api/shared/enums'
import { useDispatch } from '@centrito/app/store'
import { actions } from '@centrito/app/store/slices/userData'
import {
  LILI_PINK_MERCHANT_ID,
  OFFCORSS_MERCHANT_ID,
  OUTLET_MERCHANT_IDS,
  TOTTO_MERCHANT_ID,
  getSearchVariant,
} from '@centrito/app/utils/helpers'
import { useFiltersQuery } from '@centrito/app/utils/hooks/useFeedProducts/useFiltersQuery'
import useWindowDimensions from '@centrito/app/utils/hooks/useWindowDimensions'
import { getPercentageDiscount } from '@centrito/app/utils/products'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'
import type { FeedType } from '@centrito/app/utils/services/analytics/posthog/utils/FeedType'
import { XStack, YStack, ZStack } from '@centrito/ui/src'
import { LiliPinkSmallDiscountTag } from '@centrito/ui/src/components/outlet/DiscountTag/LiliPink/Small'
import { OffcorssSmallDiscountTag } from '@centrito/ui/src/components/outlet/DiscountTag/Offcorss/Small'
import { TottoSmallDiscountTag } from '@centrito/ui/src/components/outlet/DiscountTag/Totto/Small'
import {
  CompositePrice,
  FlashSale,
  PercentageDiscount,
  ProductDisplayVariants,
  ProductImage,
} from './components'

export interface FeedProductDisplayProps {
  product: ProductExtendedPublicComposite
  feedType?: FeedType
  index?: number
  isCartButton?: boolean
}

type SearchBarQueryParams = {
  searchTerm: string
}
const { useParam } = createParam<SearchBarQueryParams>()

export const ProductDisplay: React.FC<FeedProductDisplayProps> = ({
  product,
  feedType,
  index,
  isCartButton = false,
}) => {
  const { filters } = useFiltersQuery()
  const [priceRange, setPriceRange] = useState<{
    low: number | undefined
    high: number | undefined
  }>({
    low: undefined,
    high: undefined,
  })
  const percentageDiscount = !isNil(product) ? getPercentageDiscount(product) : 0
  const isFlashSale = 0

  const filteredColors = filters[GetProductsFilterType.COLOR]?.join(' ')
  const [searchTermUrlPath] = useParam('searchTerm')
  const searchTermProduct = searchTermUrlPath ?? filteredColors ?? null

  const [variantIndex, setVariantIndex] = useState<number>(
    searchTermProduct ? getSearchVariant(searchTermProduct, product.variants) : 0,
  )
  const [variantImage, setVariantImage] = useState<string>(
    product.variants[variantIndex]?.images[0]?.url || '',
  )

  const toProductLink = useMemo(
    () => ({
      pathname: PATHS.Products.Product,
      query: { productId: product.product.id, variantIndex: variantIndex },
    }),
    [product.product.id, variantIndex],
  )
  const router = useRouter()
  const dispatch = useDispatch()

  const handleImagePress = useCallback((): void => {
    if (!isNil(feedType) && !isNil(index)) {
      posthogClient.captureProductClick(feedType, product.product.id, product.product.name, index)
      dispatch(actions.setSessionProductIds({ productId: product.product.id }))
    }
    router.push(toProductLink)
  }, [feedType, index, product, router, dispatch, toProductLink])

  useEffect(() => {
    setVariantImage(product.variants[variantIndex]?.images[0]?.url || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantIndex])

  useEffect(() => {
    const prices: number[] = product.variants
      .flatMap((variant) => variant.variant.data.sizes.map((size) => size.customPriceRetail))
      .filter((price): price is number => !isNil(price) && price >= 0)
    if (prices.length === 0) {
      prices.push(product.product.pricingData.priceSubtotal)
    }
    setPriceRange({ low: Math.min(...prices), high: Math.max(...prices) })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])
  const { width: _width } = useWindowDimensions()
  const responsiveWidth = _width < 500 ? _width / 2 - 7 : _width / 2 - 25
  const responsiveHeight = responsiveWidth * 1.1
  const retailPrice = product?.product.pricingData.priceSubtotal

  const determineDiscountTag = useCallback(() => {
    if (percentageDiscount === 0) {
      return undefined
    }

    if (OUTLET_MERCHANT_IDS.includes(product.merchant.id)) {
      const { id } = product.merchant

      if (id === TOTTO_MERCHANT_ID) {
        return <TottoSmallDiscountTag discount={percentageDiscount} />
      }

      if (id === LILI_PINK_MERCHANT_ID) {
        return <LiliPinkSmallDiscountTag discount={percentageDiscount} />
      }

      if (id === OFFCORSS_MERCHANT_ID) {
        return <OffcorssSmallDiscountTag discount={percentageDiscount} />
      }
    }

    return <PercentageDiscount productDiscount={percentageDiscount} />
  }, [percentageDiscount, product])

  return (
    <YStack
      id={product.product.id}
      marginVertical={3}
      height={_width / 500 < 1 ? responsiveHeight + 50 : 305}
      width={responsiveWidth}
      marginHorizontal={3}
      borderRadius={8}
      padding={0}
      backgroundColor="$pureWhite"
    >
      {product.variants.length > 1 && (
        <YStack
          position="absolute"
          justifyContent="flex-end"
          alignItems="flex-end"
          zIndex={2}
          bottom={70}
          right={8}
          gap={3}
          width={18}
        >
          <ProductDisplayVariants
            variants={product.variants}
            variantIndex={variantIndex}
            setVariantIndex={setVariantIndex}
            maxVariantDisplay={2}
            toProductLink={toProductLink}
          />
        </YStack>
      )}
      <XStack overflow="hidden" marginBottom={8} justifyContent="center" zIndex={0}>
        <ZStack height={responsiveHeight} width={responsiveWidth} zIndex={2}>
          <YStack position="absolute" zIndex={2}>
            <Pressable onPress={handleImagePress}>
              <ProductImage
                width={responsiveWidth}
                height={responsiveHeight}
                variantImage={variantImage}
                alt={product.product.name}
              />
            </Pressable>
          </YStack>
          <YStack position="absolute" justifyContent="flex-end" zIndex={2} bottom={4} left={0}>
            {determineDiscountTag()}
          </YStack>
          <YStack justifyContent="flex-start" alignItems="flex-start" zIndex={2} top={4}>
            {isFlashSale != 0 && <FlashSale productDiscount={percentageDiscount} />}
          </YStack>
        </ZStack>
      </XStack>
      <CompositePrice
        productName={product.product.name}
        productDiscount={product.product.pricingData.priceDiscountItem}
        priceRange={priceRange}
        isListPrice={
          product.highestCampaign?.type ===
            PromotionalCampaignDiscountPresentation.PUBLIC_MAGAZINE_DISCOUNT &&
          product.product.pricingData.priceDiscountItem != 0
        }
        isPublicDiscount={
          product.highestCampaign?.type ===
            PromotionalCampaignDiscountPresentation.PUBLIC_STANDARD_DISCOUNT &&
          product.product.pricingData.priceDiscountItem != 0
        }
        isCartButton={isCartButton}
        toProductLink={toProductLink}
        retailPrice={retailPrice}
      />
    </YStack>
  )
}
