import React, { useContext } from 'react'
import { FilterOverlayContext } from '@centrito/app/components/Feed/FilterBar/Overlay/context'
import { formatPrice } from '@centrito/app/utils/products'
import { Slider, XStack, YStack } from '@centrito/ui/src'
import { DefaultTextSecondary, DefaultTextTertiary } from '@centrito/ui/src/components/text'

export const PriceSlider: React.FC = () => {
  const { priceRange, setPriceRange, priceRangeBoundaries } = useContext(FilterOverlayContext)

  return (
    <YStack rowGap={48} paddingHorizontal={40} marginTop={12} marginBottom={64}>
      <DefaultTextSecondary fontSize={17} color="#7F7F7F">
        Elige el rango de precio que quieres
      </DefaultTextSecondary>
      <Slider
        max={priceRangeBoundaries.high}
        min={priceRangeBoundaries.low}
        value={priceRange}
        onValueChange={setPriceRange}
        step={1000}
      >
        <Slider.Track backgroundColor="#E9E9EA">
          <Slider.TrackActive backgroundColor="#4724A3" />
        </Slider.Track>
        <Slider.Thumb index={0} backgroundColor="#E9E9EA" borderColor="#4724A3" circular />
        <Slider.Thumb index={1} backgroundColor="#E9E9EA" borderColor="#4724A3" circular />
      </Slider>
      <XStack justifyContent="space-between">
        <DefaultTextTertiary fontSize={16}>{formatPrice(priceRange[0])}</DefaultTextTertiary>
        <DefaultTextTertiary fontSize={16}>{formatPrice(priceRange[1])}</DefaultTextTertiary>
      </XStack>
    </YStack>
  )
}
