import React, { useEffect, useState } from 'react'
import { Search } from '@tamagui/lucide-icons'
import { go } from 'fuzzysort'
import { GetProductsFilterType } from '@centrito/api/shared/enums'
import type { FilterOverlay } from '@centrito/app/components/Feed/FilterBar/Overlay/context/types'
import { Sheet, YStack } from '@centrito/ui/src'
import { BaseInput } from '@centrito/ui/src/components/input'
import { BrandGroup, CategoryGroup, ColorGroup, MerchantGroup, SizeGroup } from './Group'

interface OptionGroupProps {
  items: FilterOverlay.OptionItem[]
}

const renderBody = (
  options: FilterOverlay.OptionItem[],
  filterType: GetProductsFilterType,
): JSX.Element => {
  if (filterType === GetProductsFilterType.SIZE) {
    return <SizeGroup options={options} />
  }

  if (filterType === GetProductsFilterType.BRAND_ID) {
    return <BrandGroup options={options} />
  }

  if (filterType === GetProductsFilterType.COLOR) {
    return <ColorGroup options={options} />
  }

  if (filterType === GetProductsFilterType.CATEGORY) {
    return <CategoryGroup options={options} />
  }

  if (filterType === GetProductsFilterType.SUPPLIER) {
    return <MerchantGroup options={options} />
  }

  return <React.Fragment />
}

const _OptionGroup: React.FC<OptionGroupProps> = ({ items }) => {
  const [searchParameter, setSearchParameter] = useState<string>('')
  const [queriedOptions, setQueriedOptions] = useState<FilterOverlay.OptionItem[]>(items)
  const filterType = items?.[0]?._filterItem?.type ?? GetProductsFilterType.BRAND_ID

  useEffect(() => {
    if (searchParameter !== '') {
      const results = go(searchParameter, items, { key: 'name', threshold: -10000 })
      const matchedItems = results.map((result) => result.obj)

      setQueriedOptions(matchedItems)
      return
    }

    setQueriedOptions(items)
  }, [searchParameter, items])

  return (
    <React.Fragment>
      <YStack paddingHorizontal={24}>
        <BaseInput
          name="search-filters"
          placeholder="Buscar"
          iconLeft={<Search color="#7F7F7F" />}
          hasShadow={false}
          placeholderTextColor="#B3B3B3"
          backgroundColor="#EBF0F1"
          textValue={searchParameter}
          setTextValue={setSearchParameter}
        />
      </YStack>
      <Sheet.ScrollView borderBottomColor="#EDEDED" borderBottomWidth={1}>
        {renderBody(queriedOptions, filterType)}
      </Sheet.ScrollView>
    </React.Fragment>
  )
}

export const OptionGroup = React.memo(_OptionGroup)
