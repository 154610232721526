import type { Dispatch, SetStateAction } from 'react'
import isNil from 'lodash.isnil'
import { GetProductsFilterType } from '@centrito/api/shared/enums'
import type { FilterOverlay } from './types'

const generateEmptyOptions = (
  options: FilterOverlay.Options,
  currentFilterDrawer?: GetProductsFilterType,
): FilterOverlay.Options => {
  return Object.fromEntries(
    Object.entries(options).map(([filterTypeString, optionItems]) => {
      const filterType = parseInt(filterTypeString) as GetProductsFilterType

      if (currentFilterDrawer === filterType || isNil(currentFilterDrawer)) {
        return [filterType, optionItems?.map((optionItem) => ({ ...optionItem, isChecked: false }))]
      }

      if (currentFilterDrawer === GetProductsFilterType.PRICE_RANGE) {
        return [filterType, []]
      }

      return [filterType, optionItems]
    }),
  ) as FilterOverlay.Options
}

const useClearOptions = (
  setCurrentOptions: Dispatch<SetStateAction<FilterOverlay.Options>>,
  initialOptions: FilterOverlay.Options,
  currentFilterDrawer?: GetProductsFilterType,
): { clearOptions: () => void } => {
  const clearOptions = (): void =>
    setCurrentOptions(generateEmptyOptions(initialOptions, currentFilterDrawer))
  return { clearOptions }
}

export default useClearOptions
